import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Footer from '@sections/Footer';

import HighlightedText from '@components/common/Generic/HighlightedText';
import Services from '@components/sections/Services';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <HighlightedText />
    <Services />
    <Footer />
  </Layout>
);

export default IndexPage;
