import React from 'react';
import styled, { keyframes } from 'styled-components';
// import { graphql } from 'gatsby';
// import Img from 'gatsby-image';

import { Section, Container } from '@components/global';
import {
  logGAEvent,
  CATEGORY_PACKAGE_BLOCK,
  CATEGORY_PACKAGE_BLOCK_NOT_SURE,
  ACTION_CLICKED,
  ACTION_HOVERED,
  LABEL_HOME_FREE_SESSION_BUTTON
} from '@utils/analytics';

const zoomout = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
`;

const PackageContainer = styled.div`
  // background-color: rgb(236,236,236, 0.3);
  position: relative;
  padding: 20px 20px 20px 20px;
  min-height: 590px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  box-shadow: 0 6px 6px 0 rgba(0,0,0,.3);
  margin: 30px 0px 30px 30px;
  z-index: 100;
  text-align: center;

  &:hover {
    animation: ${zoomout} 0.5s;
    animation-fill-mode: forwards;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    min-height: 0px;
    margin: 0px 0px 40px 0px;

    &:hover {
      animation: none;
    }
  }
`
  
const ProductLi = styled.li`
  width: 32%;
  float: left;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: auto;
    margin: 0px 20px;
  }
`

const QuoteAnchor = styled.div`
  position: absolute;
  bottom: 30px;
  width: 88%;
  z-index: 1000;

  a {
    display: block;
    text-decoration: none;
    border: 1px solid rgb(4,103,232, 0.3);
    border-radius: 18px;
    padding: 10px 12px;
    color: white;
    background-color: rgb(4,103,232);
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.1);
    font-weight: 1000;
    font-size: 16px;
    word-break: break-all;
    width: 100%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    position: relative;
    bottom: auto;
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;

    a {
      width: 100%;
    }
  }
`

const sendGAEvent = ({ action, category }) => {
  logGAEvent({
    category: category.category,
    action,
    label: LABEL_HOME_FREE_SESSION_BUTTON(action),
    value: category.value
  })
}

const Package = ({ title, subTitle, description, index }) => {
  return <PackageContainer>
    <div><h3 style={{ color: 'rgb(4,103,232)', textAlign: 'center', marginBottom: 20, minHeight: 120 }} >{title}</h3></div>
    <div><h4 style={{ lineHeight: 1.5, textAlign: 'center' }}>{subTitle}</h4></div>
    <div style={{ textAlign: 'justify', color: 'rgba(0,0,0, 0.7)' }}>{description}</div>
    <QuoteAnchor
      onClick={() => sendGAEvent({ action: ACTION_CLICKED, category: CATEGORY_PACKAGE_BLOCK })}
      onMouseEnter={() => sendGAEvent({ action: ACTION_HOVERED, category: CATEGORY_PACKAGE_BLOCK })}
    >
      <a href="/contact/">Get Customized Quote</a>
    </QuoteAnchor>
  </PackageContainer>
}

const About = () => (

  <div style={{ textAlign: 'justify' }}>
    <Section id="about us" accent="secondary" style={{ marginBottom: -30, paddingBottom: 90 }}>
      <div style={{ height: 80 }}></div>
      <Container>
        <h1 style={{ textAlign: 'center' }}>Our Services</h1>
        <br />
        <h3 style={{ textAlign: 'center' }}>The One-Stop Solution for All Your Study Abroad Milestones</h3>
        <br />
        <br />
        <br />
        <p>We take pride in our knowledge and process based consultancy. Clients can personalise the services to suit their specific needs. We DO NOT follow One Glove Fits All approach and practice Customisation to the core. For us, every student is an untold story.</p><br />
        <p>We promise to listen to you through and polish you, not just to reach your dream school but also, transition into professionals with organizational and self-advocacy skills. Admission Rush provides guidance, insights, timelines, tips, support, and a framework for better decision-making. In short, we bring clarity to admissions, help alleviate stress, and above all, establish excellence!</p>
      </Container>
    </Section>
    <Section id="about us" style={{ marginBottom: -30, paddingBottom: 90 }}>
      <div style={{ height: 40 }}></div>
      <Container style={{ textAlign: 'center' }}>
        <h2>Our Popular Packages</h2>
        <br />
        <div style={{fontSize: 17}}>
          Not sure which package to choose?&nbsp;
          <a
            href="/contact/"
            onClick={() => sendGAEvent({ action: ACTION_CLICKED, category: CATEGORY_PACKAGE_BLOCK_NOT_SURE })}
            onMouseEnter={() => sendGAEvent({ action: ACTION_HOVERED, category: CATEGORY_PACKAGE_BLOCK_NOT_SURE })}
          >
            Schedule a Free Session to find out
          </a>
        </div>
        <br />
        <br />
        <ul style={{ listStyleType: 'none', margin: '0px auto', padding: 0, overflow: 'hidden' }}>
          <ProductLi>
            <Package
              title="Comprehensive Masters Admissions"
              subTitle="Enrolment Window: Starting April to August of the Application Year. *Students joining in the penultimate year of their undergrad studies shall receive extended profile building and writing sessions."
              description="This package is ideal for students thinking of/applying for Masters's courses overseas. You can bank upon us for the course and major shortlisting, career prospects, country selection, profile evaluation and building, extensive and well-researched college choices, application writeup brainstorming and editing, application filing and review, special sessions on resume writing, interview preparation and visa."
              index={0}
            />
          </ProductLi>
          <ProductLi>
            <Package
              title="Comprehensive MBA Admissions"
              subTitle="Enrolment Window: Starting April to July of the Application Year"
              description="MBA applications can be daunting when it comes to presenting a well-rounded profile to schools. Moreover, it's important to weigh the pros and cons and the ROI of the country and the institute. Don’t worry we have your back! Ranging from profile strengthening to college choices to application write-ups and interviews, we will handhold you through the entire process. Our campus insights and application strategies will help you sail through smoothly and reach your dream school."
              index={1}
            />
          </ProductLi>
          <ProductLi>
            <Package
              title="Comprehensive Undergrad Admissions - Junior Year"
              subTitle="Enrolment Window: Starting April  to August of Grade 11"
              description="This package follows a focussed approach of preparing Grade 11 students for accomplishing all study abroad milestones to reach their target colleges. We will assist you with customized timelines and testing insights and schedule, in-depth profile analysis and building sessions, extensive college choices based on family priorities, financial aid and scholarship hunt, alumni networking opportunities, application writeup brainstorming and editing, application filing and review, interview preparation, visa, and pre-departure orientation."
              index={2}
            />
          </ProductLi>
          <ProductLi>
            <Package
              title="Comprehensive Undergrad Admissions - Senior Year"
              subTitle="Enrolment Window: Starting April to July of Grade 12"
              description="A very targeted package oriented towards profile strengthening, shortlisting the best-fit schools, creating an application theme, and filing applications with well-edited and brainstormed essays to get you through your dream schools. Don’t you worry, the stringent timeline will NOT impact our quality!"
              index={3}
            />
          </ProductLi>
          <ProductLi>
            <Package
              title="Comprehensive Undergrad Admissions - Early High School"
              subTitle="Enrolment Window: Starting April  to August of Grade 09 and Grade 10"
              description="An ideal package for students who already have an inclination for pursuing their undergrad studies overseas. This is a great way to begin early college planning and also have access to the supplemental parent workshops that we provide especially for our package clients. The package is focussed on enhancing the student’s profile in international academics, and extracurriculars to reach elite schools globally. If a family decides to continue working with us, half of this package fee will be applied to the next package price."
              index={4}
            />
          </ProductLi>
          <ProductLi>
            <Package
              title="Undergrad Gap Year/Transfers Admission Consult"
              subTitle="Enrolment Window: Starting April of Grade to July of the Application Year"
              description={`Taking Gap-year/s or applying for Transfer can be stressful. We have you covered here, with our experience in placing students with:
                Multiple gap years
                Open schooling with gap year
                Transfer students
                Elite School Transfer Applications
              `}
              index={5}
            />
          </ProductLi>
          <ProductLi>
            <Package
              title="Application Write Up Brainstorming and Editing Session: Prompt/Regular"
              subTitle="Enrolment Window: Prompt: In this service, the response time is 24 hours. Regular: The response window is 4 working days"
              description="Use our services to come up with thought-provoking and engaging essays/personal statements. Our editing team will help you transform your drafts into capturing content for the admissions team. We’ve been trained by experts on the other side of the table, and we know how to make your application write-up stand out."
              index={6}
            />
          </ProductLi>
        </ul>
      </Container>
    </Section>
  </div>
);

export default About;
