import React from 'react';
import styled, { keyframes } from 'styled-components';

import PulsatingDot from '@components/common/Generic/PulsatingDot';
import { logGAEvent, CATEGORY_HOME_FREE_SESSION_BUTTON_MOBILE, ACTION_CLICKED, ACTION_HOVERED, LABEL_HOME_FREE_SESSION_BUTTON_MOBILE } from '@utils/analytics';

const zoomout = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.95);
  }
`;

const HighlightText = styled.div`
  border-radius: 20px;
  box-shadow: 0 2px 14px 0 rgba(255,255,255,.7);
  position: fixed;
  width: 178px;
  left: -40px;
  top: 480px;
  padding: 10px 10px 10px 53px;
  // animation: ${zoomout} 0.8s infinite;
  color: ${props => props.theme.color.white.regular};
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
  cursor: pointer;
  user-select: none;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

const sendGAEvent = ({ action }) => {
  logGAEvent({
    category: CATEGORY_HOME_FREE_SESSION_BUTTON_MOBILE.category,
    action,
    label: LABEL_HOME_FREE_SESSION_BUTTON_MOBILE(action),
    value: CATEGORY_HOME_FREE_SESSION_BUTTON_MOBILE.value
  })
}

const renderHighlightedText = () => {
  return <a
    href="/contact"
    onClick={() => sendGAEvent({ action: ACTION_CLICKED })}
    onMouseOver={() => sendGAEvent({ action: ACTION_HOVERED })}
  >
    <HighlightText>
      <div style={{ position: 'relative', left: '-25px', top: '-18px' }}>
        <PulsatingDot />
      </div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Free Session
    </HighlightText>
  </a>
}

export default renderHighlightedText